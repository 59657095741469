import React, { useState } from 'react';
import Tabs from '../components/custom-components/tabs panel/tabs.component';
import Tab from '../components/custom-components/tabs panel/tab.component';
import ModulesAccess from '../components/modules-access/Modules-Access';

interface ParametersProps {
  clientId: string;
}

const Parameters: React.FC<ParametersProps> = ({
  clientId = ''
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className="parameters-page">
      <div className="tab-content">
        {selectedTab === 0 && <ModulesAccess
              clientId={clientId} />}
      </div>
    </div>
  );
};

export default Parameters;
