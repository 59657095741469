import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

interface NotificationSnackbarProps {
    open: boolean;
    message: string;
    severity: 'success' | 'error';
    onClose: () => void;
}

const NotificationSnackbar: React.FC<NotificationSnackbarProps> = ({
    open,
    message,
    severity,
    onClose
}) => {
    const { t } = useTranslation();
    return (
        <Snackbar open={open} autoHideDuration={2500} onClose={onClose}>
            <Alert onClose={onClose} severity={severity} elevation={6} variant="filled">
                {t(message)}
            </Alert>
        </Snackbar>
    );
};

export default NotificationSnackbar;
