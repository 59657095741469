import { UserStatus } from './../../../pages/enum/UserEnum'

export class AccountUser {
  public id!: string;
  public username!: string;
  public firstName!: string;
  public lastName!: string; 
  public mail!: string;
  public status?: string;
  public password?: string;
  public role!: UserRole;
  public storeCodes!: string[];
  public groups!: string[];
  public lastUpdate?: Date;
  public isEnabled?: boolean;
  public isSso?: boolean = false;
  public isSelected?: boolean = false;

  constructor(init?: Partial<AccountUser>) {
    Object.assign(this, init);
  }

  public get isDefaultPassword(): boolean {
    return this.status === UserStatus.FORCE_CHANGE_PASSWORD;
    // UNCONFIRMED - User has been created but not confirmed.
    // CONFIRMED - User has been confirmed.
    // ARCHIVED - User is no longer active.
    // COMPROMISED - User is disabled due to a potential security threat.
    // UNKNOWN - User status is not known.
    // RESET_REQUIRED - User is confirmed, but the user must request a code and reset his or her password before he or she can sign in.
    // FORCE_CHANGE_PASSWORD - The user is confirmed and the user can sign in using a temporary password, but on first sign-in, the user must change his or her password to a new value before doing anything else.
  }
}

export class UserRole {
  public scope!: string;
  public targets!: string[];
  
  public toString(): string {
    let toString: string = `[${this.scope}]`;
    if (this.targets.length > 0) {
      toString += ` ${this.targets.join(', ')}`;
    }
    return toString;
  }
}
