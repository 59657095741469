import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import './modal.scss'

export interface Props {
    isShown: boolean;
    onClose?: any;
    onValid?: any;
    modalContent?: any;
    headerText?: string;
    children?: ReactNode;
    optionalBtn?: {btnTitle: string, btnStyle: string, btnAction: ()=>{}}[];
}
export interface State {
    isShown: boolean;
    optionalBtn?: {btnTitle: string, btnStyle: string, btnAction: ()=>{}}[];
}

class Modal extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = {isShown: props.isShown,
                    optionalBtn : this.props.optionalBtn??[]};
      this.onClose = this.onClose.bind(this);
      this.onValid = this.onValid.bind(this);
    }

    onClose = ()=>{
        if(this.props.onClose)
            this.props.onClose();
    }

    onValid =() => {
      if(this.props.onValid)
      {
        this.props.onValid();
        this.onClose();
      }
    }

    componentDidMount() {
        // Runs after the first render() lifecycle
        this.setState({optionalBtn : this.props.optionalBtn??[] })
    }

    modal = (
        <React.Fragment>
          <div className="backdrop" />
          <div className="wrapper">
            <div className="styledModal">
              <div className="headerModal">
                <div className="headerText">{this.props.headerText}</div>
              </div>
              <div className="contentModal">{this.props.modalContent}</div>
              <div className="footerModal">
                  <div>
                    <button className="customButton valid-btn right margin5" onClick={this.onValid}>Valider</button>
                    <button className="customButton cancel-btn right margin5" onClick={this.props.onClose}>Annuler</button>
                  </div>
                  <div>
                      {
                        //   this.state.optionalBtn?.map((btn:any) => (
                        //       <button className={btn.btnStyle} onClick={btn.btnAction}>{btn.btnTitle}</button>
                        //   ))
                      }
                    {/* <button className="customButton red-btn margin5 w120">Tout Supprimer</button> */}
                  </div>
              </div>
            </div>
          </div>
        </React.Fragment>
    );

    render() {
      return this.state.isShown ? ReactDOM.createPortal(this.modal, document.body) as React.ReactNode : null;
    }    
  
  }

export default Modal;