import React, { useState, useEffect } from 'react';
import './Modules-Access.scss'
import { Button, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import ConsoleAdminService from '../../../services/console-admin.service';
import { Trans } from 'react-i18next';
import Loading from '../custom-components/loading/loading';
import SaveButton from '../custom-components/saveButton/SaveButton';
import NotificationSnackbar from '../custom-components/notificationSnackbar/NotificationSnackbar';

interface ModulesAccessType {
  [key: string]: boolean;
}

interface ModulesAccessProps {
  clientId: string;
}

const ModulesAccess: React.FC<ModulesAccessProps> = ({
  clientId = '',
}) => {
  const [modulesAccess, setModulesAccess] = useState<ModulesAccessType>({});
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const init = async () => {
    try {
      if(clientId !== ''){
        const response = await ConsoleAdminService.getModuleAccess(clientId);
        const data = response.data.props as ModulesAccessType;
        if (data.BLOCKED === undefined) {
          data.BLOCKED = false;
        }
        setModulesAccess(data);
        setLoading(false);
      }
    } catch (error) {
      setSnackbarMessage('Échec de récupération des Modules Access');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    init();
  }, [clientId]);

  const handleChange = (event: { target: { name: string, checked: boolean } }) => {
    const { name, checked } = event.target;
    setModulesAccess(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const handleSave = async () => {
    try {
      const data = JSON.parse(JSON.stringify(modulesAccess));
      const response = await ConsoleAdminService.updateModuleAccess(clientId, data);
      if (response.status !== 200) {
        throw new Error('Failed to update modules access');
      }
      setSnackbarMessage('parameters.moduleaccess.messageSnackbar.modulesAccessUpdated');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to update Modules Access', error);
      setSnackbarMessage('parameters.moduleaccess.messageSnackbar.failedToUpdateModulesAccess');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  }; 

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="modules-access-page">
      <FormGroup className="modules-access-group">
        {Object.keys(modulesAccess).filter(key => key !== 'BLOCKED').map((moduleKey) => (
          <FormControlLabel
            key={moduleKey}
            control={
              <Checkbox
                checked={modulesAccess[moduleKey]}
                onChange={handleChange}
                name={moduleKey}
                style={{
                  color: modulesAccess[moduleKey] ? '#15afad' : '',
                }}
              />
            }
            label={<Trans>parameters.moduleaccess.{moduleKey}</Trans>}
          />
        ))}
      </FormGroup>
      {modulesAccess.BLOCKED !== undefined && (
        <div style={{ marginLeft: '20px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={modulesAccess['BLOCKED']}
                onChange={handleChange}
                name="BLOCKED"
                style={{ color: modulesAccess['BLOCKED'] ? 'red' :'' }}
              />
            }
            label={<Trans>parameters.moduleaccess.BLOCKED</Trans>}
          />
        </div>
      )}
      <SaveButton onClick={handleSave} />
      <NotificationSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default ModulesAccess;
