import React, { useEffect, useState } from 'react';
import './Header.scss';
import { Account } from '../../../data/models/account/account.model';
import { useLocation } from 'react-router-dom';
import UserAdminService from '../../../services/user-admin.service';
import Guid from '../../../toolbox/guid';
import Tabs from '../custom-components/tabs panel/tabs.component';
import Tab from '../custom-components/tabs panel/tab.component';
import Parameters from '../../parameters/Parameters';
import UserAdminInterface from '../../user-admin-interface/User-Admin-Interface';

interface HeaderProps {
  currentAccountList: Account[],
  value: number,
  clientId?: string
}

const Header: React.FC<HeaderProps> = ({ 
  value,
  clientId
}) => {
  const [AccountList, setAccountList] = useState<Account[]>([]);
  const [currentTab, setCurrentTab] = useState<number | string>(value);
  const [loading, setLoading] = useState(true);
  const initialClientId = clientId || '';
  const [selectedClientId, setSelectedClientId] = useState<string>(initialClientId);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string | number) => {
    setCurrentTab(newValue);
  };
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const response = await UserAdminService.getAllClient();
        const emptyAccount = new Account({
          id: '',
          name: 'Sélectionnez un compte',
        });
        const accountsWithEmpty = [emptyAccount, ...response.data.payload.data.map((acc: Account) => new Account(acc))];
        setAccountList(accountsWithEmpty);
        setLoading(false);
      } catch (error) {
        console.error('Échec de l\'initialisation des clients', error);
        setLoading(false);
      }
    };

    init();
  }, []);

  const handleClients = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const clientId = event.target.value;
    if (Guid.isGuid(clientId)) {
      setSelectedClientId(clientId);
    } else {
      console.warn(`ID client sélectionné invalide : ${clientId}`);
    }
  };
  const selectedAccount = AccountList.find(account => account.id === selectedClientId);
  const selectedAccountName = selectedAccount ? selectedAccount.name : '';
  return (
    <header className="header">
      {loading && <div className="progress-bar"></div>}
      <div className="top">
        <h1 className="title">Console manager</h1>
        {selectedClientId && <h2 className="account-name">{selectedAccountName}</h2>}
        <select value={selectedClientId} onChange={handleClients}>
          {AccountList.map((account) => (
            <option key={account.id} value={account.id}>
              {account.name}
            </option>
          ))}
        </select>
      </div>
      <div className="rows">
        {selectedClientId === '' ? (
          <div className="empty-selection-message">
            Veuillez sélectionner un compte
          </div>
        ) : (
          <Tabs value={Number(currentTab)} onChange={handleTabChange}>
            <Tab title="Paramètres">
              <Parameters 
                clientId={selectedClientId}
              />
            </Tab>
            <Tab title="Administration User">
              <UserAdminInterface
                clientId={selectedClientId}
              />
            </Tab>
          </Tabs>
        )}
      </div>
    </header>
  );
  
};

export default Header;
